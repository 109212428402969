import WrappedButton from '@/components/ui/WrappedButton';
import { GLOBAL_MODALS, globalModals } from '@/state/globalSettings';
import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useAtom } from 'jotai';
import useShouldUseRewardsBanner from '@/hooks/biz/welcomepack/useShouldUseRewardsBanner';
import useTheme from '@/theme/useTheme';
import useBindEmailOrLogin from '@/hooks/useBindEmailOrLogin';
import React from 'react';
import visitorBannerImage2 from './new-banner-06-04-2024.png';
import visitorBannerImage2Mobile from './new-banner-06-04-2024-mobile.png';
import { StaticImageData } from 'next/image';
import LocalizedLink from '@/components/ui/LocalizedLink';
import { PersistRatioImage } from '@repo/ui';
export interface VisitorBannerProps {
  title?: string;
  description?: string;
  pcImage: StaticImageData;
  mobileImage: StaticImageData;
  href?: string;
  hrefTarget?: '_blank' | '_self';
  viewMoreButtonText?: string;
  viewMoreButtonHref?: string;
  loginButtonText?: string;
  notLoginButtonText?: string;
}

const VisitorBanner = ({
  title,
  description,
  pcImage = visitorBannerImage2,
  mobileImage = visitorBannerImage2Mobile,
  viewMoreButtonHref,
  viewMoreButtonText,
  loginButtonText,
  notLoginButtonText,
  href,
  hrefTarget,
}: VisitorBannerProps) => {
  const [t] = useTranslation();

  const [, setModals] = useAtom(globalModals);
  const { requirementToBindEmailOrLogin, isLogin, isTemporaryLogin } =
    useBindEmailOrLogin();

  const { theme } = useTheme();
  const isMD = useMediaQuery(theme.breakpoints.up('md'));
  const shouldUseRewardsBanner = useShouldUseRewardsBanner();
  const tLoginButtonText = loginButtonText || t('Deposit Now');
  const tNotLoginButtonText = notLoginButtonText || t('Register Now');
  const bannerContent = () => {
    return (
      <Box
        sx={{
          flex: 1,
          width: !isMD ? '100%' : '60%',
        }}
      >
        <Typography
          variant="h6"
          fontWeight={[600, 700]}
          fontSize={{
            xs: '34px',
            sm: '20px',
            md: '20px',
            lg: '26px',
          }}
          sx={{
            lineHeight: 1.2,
            textShadow: '0px 0px 2px #000, 0px 0px 2px #000',
            textTransform: 'uppercase',
          }}
        >
          {t(title)}
        </Typography>
        {shouldUseRewardsBanner && (
          <>
            <Typography
              fontWeight={[600, 700]}
              mt={[1, 2]}
              mb={[2, 3]}
              fontSize={{
                xs: '14px',
                sm: '16px',
                md: '16px',
                lg: '22px',
              }}
              sx={{
                color: theme.palette.text.secondary,
                textShadow: '0px 0px 2px #000, 0px 0px 2px #000',
              }}
            >
              {t(description)}
            </Typography>
            {viewMoreButtonHref ? (
              <LocalizedLink
                href={viewMoreButtonHref}
                target={hrefTarget || '_blank'}
              >
                <Box
                  component={'div'}
                  sx={{
                    fontWeight: 500,
                    color: theme.palette.primary.main,
                    marginBottom: 2,
                  }}
                >
                  {t(viewMoreButtonText)}
                </Box>
              </LocalizedLink>
            ) : null}
            <WrappedButton
              size={!isMD ? 'small' : 'medium'}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (requirementToBindEmailOrLogin()) return;
                setModals({
                  [GLOBAL_MODALS.WALLET]: true,
                });
              }}
              sx={{
                background: theme.palette.primary.light,
                marginBottom: theme.spacing(2),
              }}
            >
              {isLogin || isTemporaryLogin
                ? tLoginButtonText
                : tNotLoginButtonText}
            </WrappedButton>
          </>
        )}
      </Box>
    );
  };

  return (
    <Stack
      overflow={'hidden'}
      flexDirection={'row'}
      justifyContent={'space-between'}
      gap={1}
      alignItems={'center'}
      height={{
        lg: 210,
        md: 210,
      }}
      sx={{
        position: 'relative',
        display: 'flex',
        cursor: href ? 'pointer' : '',
        [theme.breakpoints.up('md')]: {
          flexDirection: 'row-reverse',
        },
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column-reverse',
        },
      }}
      onClick={(e) => {
        e.stopPropagation();
        href && window.open(href, hrefTarget);
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          objectFit: 'contain',
          zIndex: -1,
          bottom: '0',
          [theme.breakpoints.up('md')]: {
            maxWidth: '50%',
            height: '100%',
          },
        }}
      >
        <PersistRatioImage
          objectFit="contain"
          logicHeight={700}
          logicWidth={1530}
          src={pcImage.src}
          imgStyle={{
            objectPosition: 'right',
          }}
        />
      </Box>
      {bannerContent()}
    </Stack>
  );
};

export default VisitorBanner;
