// import HomepageBannerPopup from './WelcomePackPopup';
import { isLoggedIn, userBonusInfoState } from '@/state/userInfo';
import useTheme from '@/theme/useTheme';
import { Button, Typography, useMediaQuery } from '@mui/material';
import { useAtomValue } from 'jotai';
import LoginUserBanner, { LoginUserBannerItemProps } from './LoginUserBanner';
import VisitorBanner from './VisitorBanner';
import bonus_banner from './bonus_banner.jpg';
import sports_banner from './sports_banner.jpg';
import moon_rush_banner from './moon_rush_banner.jpg';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import CommonCarouselWrapper from '@/components/ui/CommonCrouselWrapper';
import VisitorCards from './VisitorCards';
import visitorBannerImage2 from './new-banner-06-04-2024.png';
import visitorBannerImage2Mobile from './new-banner-06-04-2024-mobile.png';
import welcomeBounsBanner from './welcome-bouns-banner.webp';

/* eslint-disable-next-line */
export interface BannerBoxProps {}

export function HomepageBannerBox(props: BannerBoxProps) {
  const loggedIn = useAtomValue(isLoggedIn);
  const { theme } = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [t] = useTranslation();

  const { allBonusPacks } = useAtomValue(userBonusInfoState);

  const allowSelectedPacks = allBonusPacks.filter((p) => p.leftTimes >= 1);

  const actionButtonProps = {
    padding: '5px 14px',
    borderRadius: 1,
    height: 'auto',
  };

  const homepageBannerConfigs: LoginUserBannerItemProps[] = [
    // {
    //   image: banner1,
    //   alt: 'banner1',
    //   mainTitle: 'UP TO <em>70%</em> REWARDS',
    //   subTitle: 'Highest rewards program within the industry',
    // },
    {
      image: moon_rush_banner,
      alt: 'moon_rush_banner',
      mainTitle: t('MOON RUSH'),
      subTitle: (
        <Typography
          component={'span'}
          color={theme.palette.text.secondary}
          fontSize={'inherit'}
          fontWeight={'inherit'}
        >
          {t('Bet for a chance to win the GRAND PRIZE pool!')}
        </Typography>
      ),
      actionButton: (
        <Link href={'/casino/rush'}>
          <Button variant={'contained'} sx={actionButtonProps}>
            {t('Play Now')}
          </Button>
        </Link>
      ),
    },
    {
      image: sports_banner,
      alt: 'sports_banner',
      mainTitle: t('SPORTS BOOK'),
      subTitle: (
        <Typography
          component={'span'}
          color={theme.palette.text.secondary}
          fontSize={'inherit'}
          fontWeight={'inherit'}
        >
          {t('MoonClub Exclusive, bet on outcome of slot spins')}
        </Typography>
      ),

      actionButton: (
        <Link href={'/sports'}>
          <Button variant={'contained'} sx={actionButtonProps}>
            {t('Play Now')}
          </Button>
        </Link>
      ),
    },
  ];

  if (allowSelectedPacks) {
    homepageBannerConfigs.unshift({
      image: bonus_banner,
      alt: 'bonus_banner',
      mainTitle: (
        <Typography
          component={'span'}
          color={theme.palette.secondary.main}
          fontSize={'inherit'}
          fontWeight={'inherit'}
          lineHeight={'inherit'}
          sx={{
            lineHeight: 1,
            [theme.breakpoints.down('md')]: {
              lineHeight: 1,
            },
          }}
        >
          {t('WELCOME TO MOONCLUB')}
        </Typography>
      ),
      subTitle: (
        <Typography
          component={'span'}
          color={theme.palette.secondary.main}
          fontSize={'inherit'}
          fontWeight={'inherit'}
        >
          {t('Start Your Adventure with Exciting Benefits!')}
        </Typography>
      ),
      actionButton: (
        <Link
          href={'/benefits/deposit'}
          // onClick={(e) => {
          //   e.preventDefault();
          //   toast.success('Coming Soon');
          // }}
        >
          <Button
            variant={'contained'}
            sx={{
              background: theme.palette.secondary.main,
              color: theme.palette.text.white,
              ...actionButtonProps,
              ['&:hover']: {
                background: theme.palette.secondary.main,
              },
            }}
          >
            {t('Choose Pack Now')}
          </Button>
        </Link>
      ),
    });
  }

  // const loginBanners = Object.values(homepageBannerConfigs).map((config) => (
  //   <LoginUserBanner key={config.alt} {...config} />
  // ));

  return (
    <>
      <CommonCarouselWrapper>
        <VisitorBanner
          title={t('Blast Off with a $8,000 Welcome Bonus')}
          pcImage={welcomeBounsBanner}
          mobileImage={welcomeBounsBanner}
          viewMoreButtonText={'Find Out More'}
          viewMoreButtonHref={'/benefits/deposit'}
          href={'/benefits/deposit'}
          hrefTarget={'_self'}
        />
        <VisitorBanner
          title={t('ENJOY MORE REWARDS!')}
          description={t(
            'Get money back on every bet with Rakeback and invite friends to earn real money commission!',
          )}
          pcImage={visitorBannerImage2}
          mobileImage={visitorBannerImage2Mobile}
        />
      </CommonCarouselWrapper>
      {loggedIn ? null : <VisitorCards />}
    </>
  );
}

export default HomepageBannerBox;
